import React from 'react'
import { graphql } from 'gatsby'

import DefaultTemplate, { SiteMeta, PageContent } from '../components/DefaultTemplate'

export const pageQuery = graphql`
  fragment SiteMeta on Site {
    siteMetadata {
      siteTitle
      siteTitleShort
      siteUrl
      siteLogo
      facebookAppId
      facebookAdmins
      twitterUser
    }
  }

  fragment PageContent on Page {
    path
    title
    description
    keywords

    markdown {
      body
      frontmatter {
        image {
          alt
          file {
            meta: childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300, cropFocus: ATTENTION) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        bannerImage {
          alt
          file {
            hero: childImageSharp {
              fluid(maxWidth: 2400, maxHeight: 1000, cropFocus: ATTENTION) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }

  query MarkdownPageByPath($path: String!) {
    site {
      ...SiteMeta
    }

    page: page(path: { eq: $path }) {
      ...PageContent
    }
  }
`

interface DefaultPageData {
  site: SiteMeta
  page: PageContent
}

const DefaultPageContainer = ({ data }: { data: DefaultPageData }) => {
  const props = DefaultTemplate.selectProps({ data })
  return <DefaultTemplate {...props} />
}

export default DefaultPageContainer
